<template>
  <div class="bigContent">
    <div class="arrow" @click="returnPage">
      <img src="../../assets/arrow.png" alt />
    </div>
    <div class="mainContent">
      <div class="topTitle">向 活 动 负 责 人 出 示</div>
      <div class="codeBox">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div class="qrTitle">{{userName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import Punchclockapi from "@/api/punchclock";
export default {
  data() {
    return {
      userName: ''
    };
  },
  created() {
    //alert(window.localStorage.userinfo)


  },
  mounted() {
    const { id } = JSON.parse(window.localStorage.getItem("userinfo"));
    this.userName = (JSON.parse(window.localStorage.getItem("personalInformation"))).nickname;

    var memberMes = {};
    memberMes.id = id;
    memberMes.qrType = '1';
    var result = JSON.stringify(memberMes);// 转成JSON对象
    this.creatQrCode(result);
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    creatQrCode(result) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: String(result),
        width: 240,
        height: 240,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    indexListJavaPage() {
      Punchclockapi.catDetailById(this.params)
        .then((res) => {
          if (res.success) {
            console.log()
            this.dataContent = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
}

</script>

<style lang="less" scoped>
.bigContent {
  background-color: #ff4400;
  display: flex;
  justify-items: center;

  .arrow img {
    width: 20px;
    height: 20px;
    padding: 0.3rem 0 0 0.2rem;
  }
  .mainContent {
    margin: 2rem 0 0 0.25rem;
    width: 80vw;
    height: 50vh;
    background-color: #ffffff;
    border-radius: 10px;
    border-top: solid 50px #ffe9de;
    .topTitle {
      color: #ff5e23;
      font-size: 16px;
      text-align: center;
      margin-top: -35px;
    }
    .codeBox {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-top: 0.6rem;
      .qrTitle {
        overflow: hidden;
        margin-top: 0.2rem;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
</style>