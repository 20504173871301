import request from '@/utils/request'

// 打卡活动列表
export function getPunchclockList(params) {
  // return request({
  //   url: `/v2/api/app/ad_activity/activity/query/my/sign_up/page?page=${params.page}&size=${params.size}&memberId=${params.memberId}`,
  //   method: 'get',
  // })
  return request({
    url: `/v2/api/app/ad_activity//activity/query_my_activity?page=${params.page}&size=${params.size}&memberId=${params.memberId}`,
    method: 'get'
  })
}
// 打卡详情
export function catDetailById(params) {
  console.log(params);
  return request({
    url: `/v2/api/app/ad_activity/activity/query_web_info?id=${params.id}&memberId=${params.memberId}`,
    method: 'get',
  })
}
//打卡详情 二期
export function catDetailByIdSec(params) {
  console.log(params);
  return request({
    url: `/v2/api/app/ad_activity//activity/query_web_info/second_phase?id=${params.id}&memberId=${params.memberId}`,
    method: 'get',
  })
}
// 查询当前活动志愿者打卡情况
export function catMemberById(activityId) {
  return request({
    url: `/v2/api/app/ad_activity/activity/query/activity/member/sign_in_count?activityId=${activityId}`,
    method: 'get',
  })
}
// 活动签到签退
export function signActivity(data) {
  return request({
    url: `/v2/api/app/ap_member/member/add/sign_in`,
    method: 'post',
    data
  })
}
// 活动签到签退 二期
export function signActivitySec(data) {
  return request({
    url: `/v2/api/app/ap_member//member/add/sign_in/second_phase`,
    method: 'post',
    data
  })
}
// 获取扫码活动详情
export function getScanActivityDetail(params) {
  return request({
    url: `/v2/api/app/ap_member//member/query_info`,
    method: 'get',
    params
  })
}
//双向打卡
export function punchById(params) {
  return request({
    url: `/v2/api/app/ap_member//member/add/sign_in/sweep_code`,
    method: 'post',
    params

  })
}
// 获取扫码活动名字详情
export function getScanActivityName(params) {
  return request({
    url: `/v2/api/app/ad_activity//screen/queryActivityInfo`,
    method: 'get',
    params
  })
}
export default { getScanActivityName, punchById, getScanActivityDetail, getPunchclockList, catDetailById, catMemberById, signActivity, signActivitySec, catDetailByIdSec }